import React, { useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Stepper, Step, StepButton } from "@mui/material";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import { cliManagement, addPeopleTitle } from "../../constants/attorney/lables";
import {
  attorney_LandingPage,
  attorney_uploadClientDocuments,
  attoney_foldersPage,
  attoeney_client_addPeople,
} from "../../constants/routes";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { useLazyGetStakeHolderDetailsQuery } from "../../slices/stackHolderApiManagmentSlice";
import {
  setStackHolderDetails,
  setButtonEvent,
  setStackHolderEmpty
} from "../../slices/stackHolderSlice";
import ExecutorInformation from "../../components/attorney/people/ExecutorInformation";
import SuccessorInformation from "../../components/attorney/people/SuccessorInformation";
import {ReactComponent as SkippedIcon} from "../../assets/images/skippedIcon.svg";
import {ReactComponent as LineIcon} from "../../assets/images/line.svg";
import {stepStyle, stepSkipStyle} from "../../constants/stepperStyles";
import WitnessInformation from "../../components/attorney/people/WitnessInformation";
export default function AttorneyClientAddPeople() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: attorney_LandingPage,
      content: cliManagement,
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: attoney_foldersPage,
      content: "--",
      active: false,
      state: { user: "", activeTab: "" },
    },
    {
      id: "item-3",
      link: attorney_uploadClientDocuments,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-4",
      link: attoeney_client_addPeople,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [pageLoader, setPageLoader] = useState(false);
  const [skipAcivity, setSkipActivity] = useState(new Set());
  const [skipped, setSkipped] = useState(false);
  const [getAPICall, setGetAPICall] = useState(false);
  const { stackHolders } = useSelector((state) => state.stackholder);
  const [trigger, { isLoading: getStackHolderLoading }] =
  useLazyGetStakeHolderDetailsQuery();
  const steps = [
    {
      component: (
        <ExecutorInformation
          //onPrevious={() => prevStep()}
          onNext={() => nextStep()}
          getAPICall={getAPICall}
        />
      ),
      label: "Executor Information",
      title: "Add Executor Information",
    },
    {
      component: (
        <SuccessorInformation
          onPrevious={() => prevStep()}
          onNext={() => nextStep()}
          onSkip={() => skipStep()}
        />
      ),
      label: "Sucessor Information",
      title: "Add Sucessor Information",
    },
    {
      component: <WitnessInformation onPrevious={() => prevStep()}  />,
      label: "Witness Information",
      title: "Add Witness Information",
    },
    // Add more steps as needed
  ];
  const getStackHolderDetails = useCallback(async () => {
    setPageLoader(true);
    try {
      const result = await trigger(location?.state?.user?.clientId).unwrap();
      if (result && result?.stepsCompleted>0) {
        let tempResult= result?.stepsCompleted;
        if(location?.state?.step && location?.state?.step==="addsuccesor"){
          tempResult = 1;
        }
        dispatch(setStackHolderDetails(result));
        setActiveStep(tempResult)
        setTimeout(() => {
          setPageLoader(false);
        }, 5000);
        setGetAPICall(true);
        //(result);
      } else {
        setPageLoader(false);
        toast.error(result?.message);
        setGetAPICall(true);
      }
    } catch (error) {
      setPageLoader(false);
      setGetAPICall(true);
      toast.error(error?.data?.message);
    }
  }, [trigger, location?.state?.user?.clientId, location?.state?.step, dispatch]);
  useEffect(() => {
    if (location?.state?.user) {
      setItems([
        {
          id: "item-1",
          link: attorney_LandingPage,
          content: cliManagement,
          active: false,
        },
        {
          id: "item-2",
          link: attoney_foldersPage,
          content: location?.state?.user?.fullName,
          active: false,
          state: { user: location?.state?.user },
        },
        {
          id: "item-3",
          link: attorney_uploadClientDocuments,
          content: location?.state?.doc?.rootDocName,
          active: false,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
        {
          id: "item-4",
          link: attoeney_client_addPeople,
          content: addPeopleTitle[activeStep],
          active: true,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
      ]);       
    }
  }, [activeStep, location, location?.state?.doc, location?.state?.user, stackHolders.familyMember]);
  useEffect(() => {
    if(location?.state?.user?.clientId){
      getStackHolderDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location?.state?.user?.clientId])
  useEffect(() => {
    return () => {
      dispatch(setStackHolderEmpty())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const prevStep = () => {
    if (activeStep === 0) return;
    dispatch(setButtonEvent(true));
    setActiveStep((prevState) => prevState - 1);
    setSkipActivity(new Set());
    setSkipped(false);
  };
  const nextStep = () => {
    if (activeStep === 2) return;
    dispatch(setButtonEvent(true));
    setActiveStep((prevState) => prevState + 1);
  };
  const skipStep = () => {
    if(activeStep < 3) setActiveStep(prevStep => prevStep +1);
    setSkipActivity(prevSkip => {
      const newSkipSet = new Set(prevSkip.values());
      newSkipSet.add(activeStep); 
      return newSkipSet;
    })
    setSkipped(true);
  }
  const handleStep = (i) => {
    setActiveStep(i);
  }
  return (
    <>
      <div
        className="attorney-client-title-page-container px-1  px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={addPeopleTitle[activeStep]}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container add-people-container  px-1 px-lg-3 px-xl-3 px-md-4">
            <div className="stepper-container m-auto pt-3">
              <Stepper activeStep={activeStep} alternativeLabel sx={skipped ?
                stepSkipStyle:stepStyle}>
                {steps.map((step, index) => {
                  let  stepProps={}
                  let skipped=false;
                  if(skipAcivity.has(index)){
                    stepProps.completed=false;
                    skipped=true;
                    }
                  return (
                    <Step key={index} {...stepProps}>
                      {
                        skipped ?
                        <StepButton onClick={() =>handleStep(index)} icon={
                        <div className="skip-icon-container"><span className="circle-icon"><SkippedIcon /></span><span className="line-icon"><LineIcon height={10} /></span></div>}>
                        {step.label}
                      </StepButton>
                      :
                      <StepButton onClick={() =>handleStep(index)}>
                        {step.label}
                      </StepButton>
                      }                      
                    </Step>
                  );
                })}
              </Stepper>
            </div>
            <div className="add-people-form pt-3 px-0">
              {steps[activeStep].component}
            </div>
          </div>
        </Container>
      </div>
      {(getStackHolderLoading || pageLoader) && <Loader />}
    </>
  );
}
