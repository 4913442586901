import { documentApiSliceV2 } from "./documentApiSliceV2";
export const documentApiManagementV2 = documentApiSliceV2.injectEndpoints({
  endpoints: (builder) => ({
    shareDocumentV2: builder.mutation({
      query: ({docId, data}) => {
        // Log the data parameter
        console.log("Data sent in shareDocumentV2:", data);
        
        return {
          url: `/share/${docId}/share`,
          method: "POST",
          body: data,
        };
      },
    }),
    sendOtp: builder.mutation({
        query: (data) => ({
            url: `/share/otp/${data.identifierID}/send?action=${data.requestType}`,
            method: "POST",
        }), 
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
          url: `/share/otp/${data.identifierID}/verify?otp=${data.otp}`,
          method: "POST",
      }), 
  })
  }),
});

export const {
  useShareDocumentV2Mutation,
  useSendOtpMutation,
  useVerifyOtpMutation
 } = documentApiManagementV2;
