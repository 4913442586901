import React, { useEffect, useState } from "react";
import {
  Container,
  Accordion,
  Card,
  Form,
  Table,
  Button,
  Alert
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clientTitle, receiveFiles, receivefilesNote } from "../../constants/client/labels";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { PlusLg, DashLg } from "react-bootstrap-icons";
import {
  client_LandingPage,
  client_receivefiles,
} from "../../constants/routes";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import Loader from "../../components/Loader/Loader";
import ReceiveFilesModalPopup from "../../components/modal/ReceiveFilesModalPopup";
import ModalPopup from "../../components/modal/ModalPopup";
import SuccessModalContent from "../../components/widgets/SuccessModalContent";
import { useGetClientProfessionalAccessQuery } from "../../slices/additionalProfApiManagementSlice";
export default function ClientReceiveFiles() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setSuccessModal]= useState(false);
  const [folderDocTypes, setfolderDocTypes] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: client_receivefiles,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
  ]);
  const { userInfo } = useSelector((state) => state.auth);
  const { data, error, isLoading } = useGetClientProfessionalAccessQuery(userInfo?.userId, {
    skip: userInfo?.userId === undefined,
  });
  
  useEffect(() => {
    if (location?.state) {
      setItems([
        {
          id: "item-1",
          link: client_LandingPage,
          content: clientTitle,
          active: false,
          state: { id: "", activeTab: "" },
        },
        {
          id: "item-2",
          link: client_receivefiles,
          content: receiveFiles,
          active: false,
          state: { id: "", activeTab: "" },
        },
      ]);
    }
  }, [location]);
  useEffect(() => {
    if(data?.length>0){      
      setfolderDocTypes(data);
    }
  },[data]);
  
  const handlePrevPage = () => {
    navigate(client_LandingPage);
  };
  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    return <div onClick={decoratedOnClick}>{children}</div>;
  };
  const handleDocument = async (e, id, index) => {
    let tempArray=[];
    //setfolderDocTypes(tempArray);
    folderDocTypes.forEach((folder, i) => {
      let tempObj={...folder};
      if(i === index){
        let selectedObj={};
        //this line causing warnig
        tempObj={...folder, documentType:[], rootDocument:{...folder.rootDocument,checked:e.target.checked}};
         if(e.target.checked){
            selectedObj={
              rootDocId:folder.rootDocument.rootDocId,
              rootDocName:folder.rootDocument.rootDocName,
              documentTypeList:[]
            }
        }else{
          let removeSelect = selectedFolders.filter(fol => fol.rootDocId !== folder.rootDocument.rootDocId);
          setSelectedFolders(removeSelect);
        }
        folder.documentType.forEach((doctype) => {
          let tempTypeObj = {...doctype, checked:e.target.checked};
          let selTypeObj={};
          tempObj.documentType.push(tempTypeObj);
          if(e.target.checked){
            selTypeObj={
              rootDocTypeId:doctype.rootDocTypeId,
              rootDocTypeName:doctype.rootDocTypeName
            }
            selectedObj.documentTypeList.push(selTypeObj);
          }
        });
        if(e.target.checked){
          if(selectedFolders.filter(fol => fol.rootDocId === folder.rootDocument.rootDocId).length){
            setSelectedFolders(prev => [...selectedFolders.map((f) => {
              let foldertemp ={...f}
              if(f.rootDocId === folder.rootDocument.rootDocId){
                foldertemp={...f,documentTypeList:selectedObj.documentType}
              }
              return foldertemp;
            })])
          }else{
            setSelectedFolders(prev => [...prev, selectedObj])
          }
        }
      }
      tempArray[i]=tempObj;
    })
    setfolderDocTypes(tempArray);
  };
  const handleDocumentType = (e, id, rootDocId) => {
    let tempArray=[];
    folderDocTypes.forEach((folder, i) => {
      let tempObj={...folder};
      if(i === rootDocId){
        tempObj={...folder, documentType:[]}
        folder.documentType.forEach((doctype, doci) => {
          let tempTypeObj = {...doctype};
          if(id === doci){
            tempTypeObj = {...doctype, checked:e.target.checked};
            let selectedFolderTemp = selectedFolders.filter(fol => fol.rootDocId === folder.rootDocument.rootDocId);
            if(e.target.checked){
              if(selectedFolderTemp.length>0){
                if((selectedFolderTemp[0].documentTypeList.filter(doc => doc.rootDocTypeId === doctype.rootDocTypeId).length===0)){
                    let pushDocType = selectedFolders.map((doc) => {
                      let mapDoc = {...doc};
                      if(folder.rootDocument.rootDocId === doc.rootDocId){
                        mapDoc.documentTypeList.push({
                          rootDocTypeId:doctype.rootDocTypeId,
                          rootDocTypeName:doctype.rootDocTypeName
                        })
                      }
                      return mapDoc;
                  })
                  setSelectedFolders(pushDocType)
                }
              }else{
                setSelectedFolders(pre => [...selectedFolders, {
                  rootDocId:folder.rootDocument.rootDocId,
                  rootDocName:folder.rootDocument.rootDocName,
                  documentTypeList:[{
                    rootDocTypeId:doctype.rootDocTypeId,
                    rootDocTypeName:doctype.rootDocTypeName
                  }]
                }])
              }
            }else{
              if(selectedFolderTemp[0].documentTypeList.filter(doc => doc.rootDocTypeId === doctype.rootDocTypeId).length>0){
                let popDocType = selectedFolders.map((doc) => {
                  let mapDoc = {...doc};
                  if(folder.rootDocument.rootDocId === doc.rootDocId){
                    mapDoc = {...doc, documentTypeList:doc.documentTypeList.filter(doc => doc.rootDocTypeId !== doctype.rootDocTypeId)}
                  }
                  return mapDoc;
                })
                setSelectedFolders(popDocType.filter(fol => ((fol.rootDocId !==folder.rootDocument.rootDocId) || (fol.rootDocId ===folder.rootDocument.rootDocId && fol.documentTypeList.length>0))));
              }
            }
          }
          tempObj.documentType.push(tempTypeObj);
        })
        tempObj = {...tempObj, rootDocument:{
          ...tempObj.rootDocument,
          checked:tempObj.documentType.filter((type) => !type.checked).length>0?false:true
        } };
      }
      tempArray[i]=tempObj;
    })
    setfolderDocTypes(tempArray);
  };
  const handleSubmitAccess = () => {
    setShowModal(true)
  }
  const handleNavigation = () => {
    setSuccessModal(false);
    navigate(client_LandingPage)
  };
  console.log("selectedFolders==========>",selectedFolders)
  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status -{" "}
          {error?.status}. {error?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div
        className="superadmin-container view-people-info-container"
        data-testid="client-receive-files-page"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={receiveFiles}
              hasBackButton={true}
              navigateToPrev={handlePrevPage}
            />
          </div>
        </Container>
      </div>
      <div
        className="firm-page-outer-container"
        data-testid="attorney-view-successor-page"
      >
        <Container fluid className="px-lg-4">
          <div className="firm-page-container attorney-view-page employee-form-container px-lg-4 px-3 ">
            <div className="receive-file-list-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2 pt-3">
              <div className="receive-file-note">
                <span className="note-label">Note: </span>{receivefilesNote}
              </div>
              <div className="mt-4">
                <Accordion
                  // defaultActiveKey={findDefault}
                  className="mb-1"
                  activeKey={activeKey}
                  alwaysOpen
                  onSelect={(selectedIndex) => setActiveKey(selectedIndex)}
                >
                  {folderDocTypes &&
                    folderDocTypes?.length > 0 &&
                    folderDocTypes.map((folder, index) => {
                      //if (folder?.rootDocument?.isCustomFolder && folder?.rootDocument?.isCustomFolder) return null;
                        let folderName= folder?.rootDocument?.isCustomFolder? folder?.rootDocument?.rootDocName:folder?.rootDocument?.rootDocDescription;
                        return (
                          <Card
                            className="custom-card mb-2"
                            key={folder.rootDocument.rootDocId}
                          >
                            <Card.Header className="custom-card-header d-flex">
                              <span>
                                <Form.Check
                                  inline
                                  name="group1"
                                  type="checkbox"
                                  value={folder?.rootDocument?.rootDocId}
                                  id={`folder-checkbox-${index}`}
                                  checked={folder?.rootDocument?.checked === null? false:folder?.rootDocument?.checked }
                                  data-testid={`folder-checkbox-${index}`}
                                  onChange={(e) =>
                                    handleDocument(e, folder.rootDocument.rootDocId, index)
                                  }
                                />
                              </span>
                              <CustomToggle  eventKey={`${folder.rootDocument.rootDocId}`}>
                                <div>
                                  <span className="pe-3">
                                    {
                                   folder?.rootDocument?.isCustomFolder?   
                                    "":activeKey.includes(folder.rootDocument.rootDocId) ? (
                                      <DashLg
                                        size={18}
                                        className="plus-icon"
                                      />
                                    ) : (
                                      <PlusLg
                                        size={18}
                                        className="plus-icon"
                                      />
                                    )}
                                  </span>
                                  <span data-testid={`folder-name-${index}`} className={folder?.rootDocument?.isCustomFolder?"custom-folder-name":""}>{
                                  
                                  (window.innerWidth <
                                    576 &&
                                    folderName
                                      .length < 25) ||
                                  window.innerWidth > 576
                                    ? folderName
                                    : folderName.slice(
                                        0,
                                        22
                                      ) + "..."
                                  }</span>
                                </div>
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={`${folder.rootDocument.rootDocId}`}>
                              <Card.Body>
                                {folder?.documentType &&
                                  folder?.documentType?.length > 0 && (
                                    <div className="doctype-table-container">
                                      <Table hover>
                                        <thead>
                                          <tr>
                                            <th scope="col" className="col-2">
                                              Document Type
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {folder?.documentType.map(
                                            (docType, doci) => {
                                              return (
                                                <tr
                                                  key={
                                                    docType.rootDocTypeId + docType.rootDocId }
                                                >
                                                  <td>
                                                    <Form.Check
                                                      inline
                                                      name="group1"
                                                      type="checkbox"
                                                      id={`inline-radio-1`}
                                                      value={docType.rootDocTypeId}
                                                      checked={docType?.checked === null ? false:docType?.checked}
                                                      data-testid={`sub-folder-${index}${doci}`}
                                                      //onChange={(e) => handlRadioButton(e)}
                                                      onChange={(e) =>
                                                        handleDocumentType(
                                                          e,
                                                          doci,
                                                          index 
                                                        )
                                                      }
                                                    />
                                                    <span className="ps-2">{docType.rootDocTypeName}</span>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  )}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      })}
                  
                </Accordion>
              </div>
              {
                (folderDocTypes &&
                folderDocTypes?.length > 0) &&
                <div className="d-flex justify-content-end mt-3 mb-4">
                <Button
                  style={{ width: `8.5rem` }}
                  className={`custom-button btn-standard ${selectedFolders?.length===0 && "btn-disable"}`}
                  type="button"
                  onClick={handleSubmitAccess}
                  data-testid="new-firm-onboard-button"
                >
                  Submit
                </Button>
              </div>
              }
            </div>
          </div>
        </Container>
      </div>
      <ReceiveFilesModalPopup
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        setSuccessCallback={
          () => {
            setShowModal(false);
            setSuccessModal(true);
          }
        }
        selectedFolders={selectedFolders}
      />
      <ModalPopup
        show={showSuccessModal}
        setShow={() => setSuccessModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text="You have requested to upload the document" />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handleNavigation}
        animation={false}
      />
      {(isLoading) && <Loader data-testid="loader" />}
    </>
  );
}
