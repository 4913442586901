import React,{ useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col, Container, Alert } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import {
  sploginEmailReqValidation,
  sploginEmailValidation,
  enterdetails,
  sploginDurationValiation,
  sploginfirstNameRegValidation,
  sploginfirstNameValidation,
  sploginlastNameRegValidation,
  sploginlastNameValidation,
  phFirstName,
  phLastName,
  phEmail,
  phPhone,
  countryCodeList
} from "../../constants/labels";
import { validation } from "../../constants/attorney/validationMessages";
import { emailRules, alphabetRules, indphonNumberRules, usphonNumberRules,phoneNumberRules } from "../../constants/regExp";
import { useSelector } from "react-redux";
import { useAdditionalProfessionalAccessMutation } from "../../slices/addtionalProfUserApiManagmentSlice";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
export default function ReceiveFilesModalPopup({
  showModal,
  setShowCallback,
  setSuccessCallback,
  selectedFolders
}) {
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selRadio, setRadio] = useState(3)
  const schema = yup.object().shape({
    firstName: yup
      .string().trim()
      .required(`${sploginfirstNameRegValidation}`)
      .matches(alphabetRules, `${sploginfirstNameValidation}`),
    lastName: yup
      .string().trim()
      .required(`${sploginlastNameRegValidation}`)
      .matches(alphabetRules, `${sploginlastNameValidation}`),  
    email: yup
      .string().trim()
      .required(`${sploginEmailReqValidation}`)
      .email(`${sploginEmailValidation}`)
      .matches(emailRules, `${sploginEmailValidation}`),
    duration: yup.string().required(`${sploginDurationValiation}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
  });
  const { userInfo } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const handleClose = () => {
    setShowCallback(false);
    reset();
    setPhoneNumber("")
    setCountryCode("")
  };
  const [professionalAccess, {isLoading, error}] = useAdditionalProfessionalAccessMutation();
  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  const handleMaxNumber = (event) => {
    let { value } = event.target;
      event.target.value = value.slice(0, 10);
    
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };
  const onSubmit = async (formData) => {
    const data = {
      userId: userInfo?.userId,
      firstName: formData?.firstName,
      lastName:formData?.lastName,
      emailId: formData?.email,
      phoneNumber: `+${formData?.phone}`,
      duration: parseInt(formData?.duration),
      rootDocumentList:selectedFolders
    };
    console.log("receive file===>",selectedFolders)
    try {
      const res = await professionalAccess(data).unwrap();
      if (res?.isSuccessful) {
        setSuccessCallback();
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };
  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data && error?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="share-document-modal"
        data-testid="recevie-files-modal-container"
        dialogClassName="share-modal-width"
      >
        <Form
          className="custom-form custom-form-doc-share w-100"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Header className="share-doc-modal-header receive-file-header" closeButton>
            <Modal.Title className="header-title">{enterdetails}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="share-doc-modal-body">
          <Row>
          <Col lg={12} md={12} className="mt-3">
            <Row>
              <Col lg={6} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor="recfiles-firstname"
                    className="required mb-0"
                  >
                    {phFirstName}
                  </Form.Label>
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    maxLength={30}
                    {...register("firstName")}
                    isInvalid={!!errors.firstName}
                    data-testid="recfiles-firstname"
                    id="recfiles-firstname"
                  />
                  <Form.Control.Feedback
                    data-testid="invalid-feedback"
                    type="invalid"
                  >
                    {errors.firstName?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor="recfiles-lastname"
                    className="required mb-0"
                  >
                    {phLastName}
                  </Form.Label>
                  <Form.Control
                    className="form-input input-border-radius-unset"
                    type="text"
                    name="lastName"
                    maxLength={30}
                    autoComplete="off"
                    {...register("lastName")}
                    isInvalid={!!errors.lastName}
                    data-testid="recfiles-lastname"
                    id="recfiles-lastname"
                  />
                  <Form.Control.Feedback
                    data-testid="invalid-feedback"
                    type="invalid"
                  >
                    {errors.lastName?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          </Row>
          <Row>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="recfiles-email" className="required mb-0">
                {phEmail}
              </Form.Label>
              <Form.Control
                className="form-input input-border-radius-unset"
                type="email"
                name="email"
                autoComplete="off"
                {...register("email")}
                isInvalid={!!errors.email}
                onBlur = {(e) => e.target.value = e.target.value.trim()}
                data-testid="recfiles-email"
                id="recfiles-email"
              />
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6} md={6}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="recfiles-phone" className="required mb-0">
                {phPhone}
              </Form.Label>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <div
                    className={
                      errors.phone ? "phone-input-invalid-container" : "phone-input-valid-container"
                    }
                  >
                    <PhoneInput
                      {...field}
                      defaultCountry="us"
                      onlyCountries={countryCodeList}
                      placeholder=""
                      title=""
                      country={"us"}
                      inputProps={{
                        required: true,
                        autoFocus: false,
                        title: "",
                      }}
                      value={phoneNumber}
                      countryCodeEditable={false}
                      onChangeCapture={handleMaxNumber}
                      onChange={(value, phone) => {
                        setPhoneNumber(value);
                        if (
                          phone.dialCode === "1" &&
                          phone.countryCode === "us"
                        ) {
                          setCountryCode("+1");
                        }
                        if (
                          phone.dialCode === "91" &&
                          phone.countryCode === "in"
                        ) {
                          setCountryCode("+91");
                        }
                      }}
                    />
                  </div>
                )}
              />
              <Form.Control
                className="form-input input-border-radius-unset input_number"
                type="hidden"
                name="phone"
                autoComplete="off"
                onKeyDown={handleKeyPress}
                onChangeCapture={handleMaxNumber}
                {...register("phone")}
                isInvalid={!!errors.phone}
                data-testid="recfiles-phone"
                id="recfiles-phone"
                value={phoneNumber}
              />
              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
          <Form.Group className="mb-3 radio-button-container">
              <Form.Label htmlFor="inline-radio-11" className="radio-button-label">
                Duration of Access
              </Form.Label>
              <div className="rec-files-radio-buttons-wrapper">
                <Form.Check
                  inline
                  className="pe-5"
                  label="1 day"
                  name="group1"
                  type="radio"
                  id={`inline-radio-11`}
                  value={1}
                  checked={selRadio === 1 ? true:false}
                  onClick={() => setRadio(1)}
                  data-testid="radio-button-3days"
                  {...register("duration")}
                />
                <Form.Check
                  inline
                  className="pe-5"
                  label="3 days"
                  name="group1"
                  type="radio"
                  id={`inline-radio-2`}
                  value={3}
                  checked={selRadio === 3 ? true:false}
                  onClick={() => setRadio(3)}
                  data-testid="radio-button-5days"
                  {...register("duration")}
                />
                <Form.Check
                  inline
                  label="5 days"
                  name="group1"
                  type="radio"
                  id={`inline-radio-3`}
                  value={5}
                  checked={selRadio === 5 ? true:false}
                  onClick={() => setRadio(5)}
                  data-testid="radio-button-7days"
                  {...register("duration")}
                />
              </div>
              {errors?.duration && (
                <p className="error-message pt-3">
                  {errors?.duration?.message}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
          <Form.Group className="mb-3">
            <Form.Control 
              as="textarea" 
              rows={3} 
              name="comments"
              placeholder="Comments" 
              {...register("comments")}
              data-testid="recfiles-comments"
              id="recfiles-comments"
              />
          </Form.Group>
          </Col>
        </Row>    
          </Modal.Body>
          <Modal.Footer className="share-doc-modal-footer justify-content-center pt-0">
            <Button
              className="custom-button btn-standard doc-share-submit-btn"
              type="submit"
              data-testid="receive-files-submit-btn"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>      
    </>
  );
}
