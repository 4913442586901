import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down-doc-part.svg";
import bytes from "bytes";
import TooltipPopover from "../tooltip/TooltipPopover";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useLocation, useNavigate } from "react-router";
import {
  client_viewPeoplePage,
  attoeney_client_addPeople,
  attorney_client_addSuccussor,
  client_addPeople,
  client_addSuccussor,
  viewPeoplePage,
  executor_viewPeoplePage,
  client_addExecutor,
  attorney_client_addExecutor,
  attorney_client_addWitness,
  client_addWitness,
} from "../../constants/routes";
import { LinkContainer } from "react-router-bootstrap";
import { ReactComponent as DeleteAction } from "../../assets/images/DeleteAction.svg";
import DocumentPreview from "../../components/modal/DocumentPreview";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useDeleteDocumentMutation } from "../../slices/documentApiManagement";
import ModalPopupType2 from "../modal/ModalPopupType2";
import { useDispatch, useSelector } from "react-redux";
import { ROLE } from "../../constants/role";
import { signOutApiCall } from "../../slices/authSlice";
import ReactGA from "react-ga4";
import { ReactComponent as Print } from "../../assets/images/print.svg";
import { ReactComponent as Share } from "../../assets/images/sharedocument.svg";
import { ReactComponent as Download } from "../../assets/images/download-icon.svg";
import ShareDocumentModalPopup from "../modal/ShareDocumentModalPopup";
import ModalPopup from "../modal/ModalPopup";
import SuccessModalContent from "../widgets/SuccessModalContent";
import { sharedDocSuccessText } from "../../constants/labels";
import {
  attorney_LandingPage,
  client_LandingPage,
  executor_LandingPage,
} from "../../constants/routes";
import dayjs from "dayjs";

export default function DocumentDetails({
  allDocumentsData,
  userDetails,
  noaction,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [progressMap, setProgressMap] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [docDetails, setDocDetails] = useState(null);
  const [selectedDocs, setSelectedDocs] = useState("");
  const [viewDocType, setViewDocType] = useState("");
  const [fileName, setFileName] = useState("");
  const [docId, setDocId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const [activeKey, setActiveKey] = useState(["0"]);
  const [showDocModal, setShowDocModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [deleteDocument, { isLoading: docDeleteLoading }] =
    useDeleteDocumentMutation();
    
  const implementPeopleSection = (file) => {
    const addPersonInfoModel = file?.addPersonInfoModel;
    const redirectToViewInformation = (type, file) => {
      if (!noaction) {
        navigate(
          userInfo?.role === ROLE.client
            ? viewPeoplePage
            : client_viewPeoplePage,
          {
            state: {
              page: "Documents",
              id: location?.state,
              viewType: type,
              file: file,
            },
          }
        );
      } else {
        navigate(executor_viewPeoplePage, {
          state: {
            page: "Documents",
            id: location?.state,
            viewType: type,
            file: file,
          },
        });
      }
    };
    // const checkFamily =
    //   addPersonInfoModel &&
    //   addPersonInfoModel.familyMember &&
    //   Object.keys(addPersonInfoModel.familyMember).length > 0;

    const checkExecutor =
      addPersonInfoModel && addPersonInfoModel.executorList?.length > 0;
    const checkSuccessor =
      addPersonInfoModel &&
      addPersonInfoModel.successor &&
      Object.keys(addPersonInfoModel.successor).length > 0;
    const checkWitness =
      addPersonInfoModel &&
      addPersonInfoModel.witness &&
      Object.keys(addPersonInfoModel.witness).length > 0;
    if (checkExecutor && checkWitness) {
      return (
        <div className="people-details-section">
          <div className="d-flex">
            People Details
            <div className="ps-2">
              <TooltipPopover
                content={
                  <>Details of the person who can refute death claims</>
                }
                placement="top"
              />
            </div>
          </div>
          <div className="people-details-inner-section pt-2 d-flex justify-content-between">
            {/* <div>
              <div>Family</div>
              <div
                onClick={() => redirectToViewInformation("family member", file)}
              >
                {file?.addPersonInfoModel?.familyMember?.firstName}
              </div>
            </div> */}
            <div>
              <div className="d-flex">
                <span className="pe-2">Executor</span>
                {file?.addPersonInfoModel?.executorList?.length < 2 &&
                  !noaction && (
                    <Button
                      className={`custom-button btn-standard plus-btn d-flex justify-content-center align-items-center mt-1`}
                      type="button"
                      onClick={() =>
                        handleAddExecutor(
                          file?.addPersonInfoModel?.executorList
                        )
                      }
                    >
                      {" "}
                      +
                    </Button>
                  )}
              </div>
              <div className="d-flex flex-column">
                {file?.addPersonInfoModel?.executorList.map((data, index) => (
                  <LinkContainer
                    to={
                      !noaction
                        ? userInfo?.role === ROLE.client
                          ? viewPeoplePage
                          : client_viewPeoplePage
                        : executor_viewPeoplePage
                    }
                    state={{
                      page: "Documents",
                      id: location?.state,
                      viewType: "executor",
                      index: index,
                      file: file,
                      noaction: noaction,
                    }}
                    key={index}
                  >
                    <span>
                      {data.firstName}
                      {/* {index !==
                        file.addPersonInfoModel.executorList.length - 1 && ", "} */}
                    </span>
                  </LinkContainer>
                ))}
              </div>
            </div>
            <div>
              <div>Successor</div>
              {userInfo?.role === ROLE.executor && !checkSuccessor ? (
                <span>N/A</span>
              ) : (
                <div>
                  {!checkSuccessor ? (
                    <LinkContainer
                      to={
                        userInfo?.role === ROLE.client
                          ? client_addSuccussor
                          : attorney_client_addSuccussor
                      }
                      state={{
                        doc: location?.state?.doc,
                        user: location?.state?.user,
                        step: "addsuccesor",
                      }}
                      className="px-1"
                    >
                      <span className="text-underline">[Add Successor]</span>
                    </LinkContainer>
                  ) : (
                    // : <><span className="no-underline">N/A</span></>
                    file?.addPersonInfoModel?.successor?.firstName && (
                      <span
                        onClick={() =>
                          redirectToViewInformation("successor", file)
                        }
                      >
                        {file?.addPersonInfoModel?.successor?.firstName}
                      </span>
                    )
                  )}
                </div>
              )}
            </div>
            <div>
              <div className="d-flex">
                <span className="pe-2">Witness</span>
                {file?.addPersonInfoModel?.witness?.length < 2 && !noaction && (
                  <Button
                    className={`custom-button btn-standard plus-btn d-flex justify-content-center align-items-center mt-1`}
                    type="button"
                    onClick={() =>
                      handleAddWitness(file?.addPersonInfoModel?.witness)
                    }
                  >
                    {" "}
                    +
                  </Button>
                )}
              </div>
              <div className="d-flex flex-column">
                {file?.addPersonInfoModel?.witness.map((data, index) => (
                  <LinkContainer
                    to={
                      !noaction
                        ? userInfo?.role === ROLE.client
                          ? viewPeoplePage
                          : client_viewPeoplePage
                        : executor_viewPeoplePage
                    }
                    state={{
                      page: "Documents",
                      id: location?.state,
                      viewType: "witness",
                      index: index,
                      file: file,
                      noaction: noaction,
                    }}
                    key={index}
                  >
                    <span>
                      {data.firstName}
                      {/* {index !==
                        file.addPersonInfoModel.executorList.length - 1 && ", "} */}
                    </span>
                  </LinkContainer>
                ))}
              </div>
              {/* <div onClick={() => redirectToViewInformation("witness", file)}>
                {file?.addPersonInfoModel?.witness?.firstName}
              </div> */}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div>
            Note: You can only hold one WILL copy at a time in the platform
          </div>
          <div className="pt-2">
            Click
            <LinkContainer
              to={
                userInfo?.role === ROLE.client
                  ? client_addPeople
                  : attoeney_client_addPeople
              }
              state={{
                doc: location?.state?.doc,
                user: location?.state?.user,
              }}
              className="px-1"
            >
              <span className="underline">HERE</span>
            </LinkContainer>
            to Add the People Information.
          </div>
        </>
      );
    }
  };

  const handleNavigation = () => {
    setSuccessModal(false);
    if (userInfo?.role === ROLE.attorney) {
      navigate(attorney_LandingPage);
    }
    if (userInfo?.role === ROLE.client) {
      navigate(client_LandingPage);
    }
    if (userInfo?.role === ROLE.executor) {
      navigate(executor_LandingPage);
    }
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    return <div onClick={decoratedOnClick}>{children}</div>;
  };

  const blobToFile = (blob, fileName) => {
    const file = new File([blob], fileName, { type: "application/pdf" });
    return file;
  };

  const handlePrint = async (docId, docName) => {
    setLoading(true);
    const baseUrl = `${
      process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE
    }/api/v1/documents/${docId}?convertPdf=${true}`;
    //const baseUrlwithPdf = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/${docId}`;
    const token = userInfo?.accessToken;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
      });

      if (response.status === 200) {
        setLoading(false);
        const url = URL.createObjectURL(response?.data);
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.onload = function () {
          iframe.contentWindow.print();
        };
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signOutApiCall());
        toast.error(`Signed out successfully due to token expiration!`);
      }
      setLoading(false);
      toast.error(`Error in Printing the file: ${error.message}`);
    }
  };

  const handleShare = (docId) => {
    setDocId(docId);
    setShowDocModal(true);
  };

  function isVideoFile(filename) {
    // Define a regular expression pattern to match video file extensions
    const videoFileExtensions =
      /\.(mp4|m4v|mkv|mov|avi|wmv|flv|webm|mpeg|mpg|3gp|3g2)$/i;

    // Test the filename against the pattern
    return videoFileExtensions.test(filename);
  }

  const handleClickWillDoc = async (docId, docName) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/${docId}`;
    setDocId(docId);
    const token = userInfo?.accessToken;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;

          if (totalLength !== null) {
            setLoading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            // Update progress for this specific file
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docId]: { progress, docName },
            }));
          }
        },
      });

      if (response.status === 200) {
        setLoading(false);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const file = blobToFile(blob, docName);
        if (userInfo?.role === ROLE.attorney) {
          ReactGA.event(`download_will_document_confirmation`, {
            category: `${ROLE.attorney} - 3 Level`,
            action: `Download will Document Confirmation - ${ROLE.attorney} - 3 Level`,
            label: `Download will Document Confirmation - ${ROLE.attorney} - 3 Level`,
            wills_downloaded: 1,
            wills_downloaded_fileszie: response?.data?.size,
          });
        }
        if (userInfo?.role === ROLE.client) {
          const firmType = userInfo?.firmTypeId;
          firmType === 1 &&
            ReactGA.event(`download_will_document_confirmation`, {
              category: `${ROLE.client} - 3 Level`,
              action: `Download will Document Confirmation - ${ROLE.client} - 3 Level`,
              label: `Download will Document Confirmation - ${ROLE.client} - 3 Level`,
              wills_downloaded: 1,
              wills_downloaded_fileszie: response?.data?.size,
            });
          firmType === 2 &&
            ReactGA.event(`download_will_document_confirmation`, {
              category: `${ROLE.client} - 2 Level`,
              action: `Download will Document Confirmation - ${ROLE.client} - 2 Level`,
              label: `Download will Document Confirmation - ${ROLE.client} - 2 Level`,
              wills_downloaded: 1,
              wills_downloaded_fileszie: response?.data?.size,
            });
        }
        setTimeout(() => {
          setProgressMap((prevProgressMap) => {
            const updatedProgressMap = { ...prevProgressMap };
            delete updatedProgressMap[docId];
            return updatedProgressMap;
          });
        }, 2000);
        setShowModal(true);
        setViewDocType("will");
        setSelectedDocs(file);
        setFileName(docName);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(signOutApiCall());
        toast.error(`Signed out successfully due to token expiration!`);
      }
      setLoading(false);
      console.error("Error downloading file:", err);
      toast.error(`${docName} downloading failed. Try again!`);
      setProgressMap((prevProgressMap) => {
        const updatedProgressMap = { ...prevProgressMap };
        delete updatedProgressMap[docId];
        return updatedProgressMap;
      });
    }
  };

  const handleDownload = async (docId, docName) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/${docId}`;
    const token = userInfo?.accessToken;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;

          if (totalLength !== null) {
            setLoading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            // Update progress for this specific file
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docId]: { progress, docName },
            }));
          }
        },
      });

      if (response.status === 200) {
        setLoading(false);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        if (userInfo?.role === ROLE.attorney) {
          ReactGA.event(`download_other_documents_confirmation`, {
            category: `${ROLE.attorney} - 3 Level`,
            action: `Download other Documents Confirmation - ${ROLE.attorney} - 3 Level`,
            label: `Download other Documents Confirmation - ${ROLE.attorney} - 3 Level`,
            others_downloaded: 1,
            others_downloaded_filesize: response?.data?.size,
          });
        }
        if (userInfo?.role === ROLE.client) {
          const firmType = userInfo?.firmTypeId;
          firmType === 1 &&
            ReactGA.event(`download_other_documents_confirmation`, {
              category: `${ROLE.client} - 3 Level`,
              action: `Download other Documents Confirmation - ${ROLE.client} - 3 Level`,
              label: `Download other Documents Confirmation - ${ROLE.client} - 3 Level`,
              others_downloaded: 1,
              others_downloaded_filesize: response?.data?.size,
            });
          firmType === 2 &&
            ReactGA.event(`download_other_documents_confirmation`, {
              category: `${ROLE.client} - 2 Level`,
              action: `Download other Documents Confirmation - ${ROLE.client} - 2 Level`,
              label: `Download other Documents Confirmation - ${ROLE.client} - 2 Level`,
              others_downloaded: 1,
              others_downloaded_filesize: response?.data?.size,
            });
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${docName}`;
        a.setAttribute("id", "download-doc");
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        // Handle downloaded file data
        toast.success(`${docName} has been downloaded successfully!`);
        setTimeout(() => {
          setProgressMap((prevProgressMap) => {
            const updatedProgressMap = { ...prevProgressMap };
            delete updatedProgressMap[docId];
            return updatedProgressMap;
          });
        }, 2000);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signOutApiCall());
        toast.error(`Signed out successfully due to token expiration!`);
      }
      setLoading(false);
      console.error("Error downloading file:", error);
      toast.error(`${docName} downloading failed. Try again!`);
      setProgressMap((prevProgressMap) => {
        const updatedProgressMap = { ...prevProgressMap };
        delete updatedProgressMap[docId];
        return updatedProgressMap;
      });
    }
  };

  const handleViewPageNavigation = async () => {
    const { docId } = docDetails;
    try {
      const res = await deleteDocument(docId).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
        setConfirmModal(false);
      } else {
        setConfirmModal(false);
        toast.error(res?.message);
      }
    } catch (err) {
      setConfirmModal(false);
      console.error("Error deleting document:", err);
      toast.error(err?.error || err?.data?.error);
    }
  };

  const handleDelete = (docId, docName) => {
    setConfirmModal(true);
    setDocDetails({ docId, docName });
  };

  const isDesktop = window.matchMedia("(min-width: 768px)").matches;

  const findDefault = () => {
    const type = location?.state?.docType;
    if (allDocumentsData && allDocumentsData.length > 0 && type) {
      let uniqueDocsMap = new Map();
      uniqueDocsMap = Object.values(
        allDocumentsData.reduce((acc, file) => {
          if (!acc[file.rootDocTypeId]) {
            acc[file.rootDocTypeId] = [];
          }
          acc[file.rootDocTypeId].push(file);
          return acc;
        }, {})
      )
        .filter((group) => group && group.length > 0)
        .sort((a, b) => {
          const rootIdA = a[0] ? a[0].rootDocTypeId : "";
          const rootIdB = b[0] ? b[0].rootDocTypeId : "";
          return rootIdA.localeCompare(rootIdB);
        });

      const getActiveKey = (docGroups, type) => {
        if (!type?.startsWith("Will has been")) {
          const index = docGroups.findIndex(
            (group) =>
              group[0]?.rootDocTypeName !== "Will" &&
              type?.includes(group[0]?.rootDocTypeName)
          );
          return index.toString();
        }
        return "0";
      };
      const key = getActiveKey(uniqueDocsMap, type);
      setActiveKey([key]);
    }
  };
  useEffect(() => {
    const type = location?.state?.docType;
    if (allDocumentsData && allDocumentsData.length > 0 && type) {
      let uniqueDocsMap = new Map();
      uniqueDocsMap = Object.values(
        allDocumentsData.reduce((acc, file) => {
          if (!acc[file.rootDocTypeId]) {
            acc[file.rootDocTypeId] = [];
          }
          acc[file.rootDocTypeId].push(file);
          return acc;
        }, {})
      )
        .filter((group) => group && group.length > 0)
        .sort((a, b) => {
          const rootIdA = a[0] ? a[0].rootDocTypeId : "";
          const rootIdB = b[0] ? b[0].rootDocTypeId : "";
          return rootIdA.localeCompare(rootIdB);
        });

      const getActiveKey = (docGroups, type) => {
        if (!type?.startsWith("Will has been")) {
          const index = docGroups.findIndex(
            (group) =>
              group[0]?.rootDocTypeName !== "Will" &&
              type?.includes(group[0]?.rootDocTypeName)
          );
          return index.toString();
        }
        return "0";
      };
      const key = getActiveKey(uniqueDocsMap, type);
      setActiveKey([key]);
    }
  }, [location?.state?.docType, allDocumentsData]);

  const handleAddExecutor = (existExecutor) => {
    if (userInfo?.role === ROLE.attorney) {
      navigate(attorney_client_addExecutor, {
        state: {
          ...location?.state,
          existExecutor: existExecutor,
        },
      });
    } else {
      navigate(client_addExecutor, {
        state: {
          ...location?.state,
          existExecutor: existExecutor,
        },
      });
    }
  };
  const handleAddWitness = (existExecutor) => {
    if (userInfo?.role === ROLE.attorney) {
      navigate(attorney_client_addWitness, {
        state: {
          ...location?.state,
          existWitness: existExecutor,
        },
      });
    } else {
      navigate(client_addWitness, {
        state: {
          ...location?.state,
          existWitness: existExecutor,
        },
      });
    }
  };
  return (
    <>
      <Accordion
        defaultActiveKey={findDefault}
        className="mb-1"
        activeKey={activeKey}
        alwaysOpen
        onSelect={(selectedIndex) => setActiveKey(selectedIndex)}
      >
        {allDocumentsData &&
          allDocumentsData.length > 0 &&
          Object.values(
            allDocumentsData.reduce((acc, file) => {
              if (!acc[file.rootDocTypeId]) {
                acc[file.rootDocTypeId] = [];
              }
              acc[file.rootDocTypeId].push(file);
              return acc;
            }, {})
          )
            .filter((group) => group && group.length > 0)
            .sort((a, b) => {
              const rootIdA = a[0] ? a[0].rootDocTypeId : "";
              const rootIdB = b[0] ? b[0].rootDocTypeId : "";
              return rootIdA.localeCompare(rootIdB);
            })
            .map((documentGroup, index) => (
              <Card
                key={documentGroup[0].rootDocTypeId}
                className="custom-card mb-1"
              >
                <Card.Header className="custom-card-header">
                  <CustomToggle eventKey={`${index}`}>
                    <div className="d-flex">
                      <div>
                        <ArrowDown />
                      </div>
                      <div className="ps-3">
                        {documentGroup[0].rootDocTypeName}
                      </div>
                    </div>
                  </CustomToggle>
                </Card.Header>

                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>
                    {documentGroup.length === 1 &&
                      documentGroup[0].rootDocTypeId === "DT_001" &&
                      documentGroup.map((file, index) => {
                        return (
                          <div key={index} className="mb-1">
                            <div className="doc-section">
                              <div className="doc-inner-section d-flex justify-content-between">
                                <div>
                                  <div>File Name</div>
                                  {
                                   userInfo?.role === ROLE.attorney?
                                    (
                                      <div
                                      className="file-name-others"
                                    >
                                      {file.docName}
                                    </div>
                                    )
                                  :
                                  isDesktop ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip className="tooltip text-nowrap">
                                          Click on the document to preview
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className="file-part"
                                        onClick={() =>
                                          handleClickWillDoc(
                                            file.docId,
                                            file.docName
                                          )
                                        }
                                      >
                                        {file.docName}
                                      </div>
                                    </OverlayTrigger>
                                  ) : (
                                    <div
                                      className="file-part"
                                      onClick={() =>
                                        handleClickWillDoc(
                                          file.docId,
                                          file.docName
                                        )
                                      }
                                    >
                                      {file.docName}
                                    </div>
                                  )}
                                </div>
                                {file.rootDocTypeId === "DT_001" && (
                                  <div>
                                    <div className="d-flex">
                                      Version{" "}
                                      <div className="ps-2">
                                        <TooltipPopover
                                          content={
                                            <>
                                              Whenever a new version of the WILL
                                              is uploaded, the previous version
                                              is archived.
                                            </>
                                          }
                                          placement="top"
                                        />
                                      </div>
                                    </div>
                                    <div>{file.version}</div>
                                  </div>
                                )}
                                <div>
                                  <div>Uploaded by</div>
                                  <div>
                                    {file.uploadedBy}
                                  </div>
                                </div>
                                <div>
                                  <div>Last Modified</div>
                                  <div>{dayjs(file.modifiedAt).format("MM/DD/YYYY h:mm A")}</div>
                                </div>
                                <div>
                                  <div>Size</div>
                                  <div>
                                    {bytes.format(file.docSize, {
                                      unitSeparator: " ",
                                    })}
                                  </div>
                                </div>
                                {file.rootDocTypeId === "DT_001" && (
                                  <div>
                                    <div>Original available</div>
                                    <div>
                                      {file?.originalAvailable &&
                                      file.originalAvailable !== "" ? (
                                        <div>
                                          {file.originalAvailable}
                                        </div>
                                      ) : (
                                        <div className="text-start text-xl-center text-xxl-center">
                                          No
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {file.rootDocTypeId === "DT_001" && (
                              <>
                                <hr />
                                <div className="people-section">
                                  {implementPeopleSection(file)}
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}

                    {documentGroup[0].rootDocTypeId !== "DT_001" &&
                      documentGroup.length >= 1 && (
                        <div>
                          <div className="doc-section">
                            <div className="doc-inner-section multiple d-flex justify-content-between d-block d-md-none ">
                              {documentGroup.map((file, index) => (
                                <div key={index} className="position-relative">
                                  <div>
                                    <div>File Name</div>
                                    <div
                                      className="file-name-others"
                                      // onClick={() =>
                                      //   handleDownload(file.docId, file.docName)
                                      // }
                                    >
                                      {file.docName}
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <div>Uploaded by</div>
                                    <div>
                                      {file.uploadedBy}
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <div>Last Modified</div>
                                    <div>{dayjs(file.modifiedAt).format("MM/DD/YYYY h:mm A")}</div>
                                  </div>
                                  <div className="mt-2">
                                    <div>Size</div>
                                    <div>
                                      {bytes.format(file.docSize, {
                                        unitSeparator: " ",
                                      })}
                                    </div>
                                  </div>
                                  {userInfo?.role === ROLE.client && (
                                    <div className="mt-2">
                                      <div className="delete-doc-others d-flex small">
                                        <div className="me-3">
                                          <Download
                                            onClick={() =>
                                              handleDownload(
                                                file.docId,
                                                file.docName
                                              )
                                            }
                                            data-testid="download"
                                          />
                                        </div>
                                        {!isVideoFile(file.docName) && (
                                          <div className="me-3">
                                            <Share
                                              onClick={() =>
                                                handleShare(file.docId)
                                              }
                                              data-testid="share"
                                            />
                                          </div>
                                        )}
                                        {!isVideoFile(file.docName) && (
                                          <div className="me-3">
                                            <Print
                                              onClick={() =>
                                                handlePrint(
                                                  file.docId,
                                                  file.docName
                                                )
                                              }
                                              data-testid="print"
                                            />
                                          </div>
                                        )}
                                        <div>
                                          <DeleteAction
                                            onClick={() =>
                                              handleDelete(
                                                file.docId,
                                                file.docName
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {userInfo?.role === ROLE.executor && (
                                    <div className="mt-2">
                                      <div className="delete-doc-others d-flex small">
                                        <div className="me-3">
                                          <Download
                                            onClick={() =>
                                              handleDownload(
                                                file.docId,
                                                file.docName
                                              )
                                            }
                                            data-testid="download"
                                          />
                                        </div>
                                        {!isVideoFile(file.docName) && (
                                          <div className="me-3">
                                            <Print
                                              onClick={() =>
                                                handlePrint(
                                                  file.docId,
                                                  file.docName
                                                )
                                              }
                                              data-testid="print"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {index === documentGroup.length - 1 ? null : (
                                    <hr />
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="d-none d-md-block">
                              <Table className="table table-responsive table-custom-document">
                                <thead>
                                  <tr>
                                    <td style={{ width: "30%" }}>File Name</td>
                                    <td style={{ width: "20%" }}>
                                      Uploaded by
                                    </td>
                                    <td style={{ width: "20%" }}>
                                      Last Modified
                                    </td>
                                    <td style={{ width: "15%" }}>Size</td>
                                    {userInfo?.role !== ROLE.attorney && <td style={{ width: "15%" }}></td>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {documentGroup.map((file, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="file-name-others">
                                          <div>{file.docName}</div>
                                        </div>
                                      </td>
                                      {/* //here */}
                                      <td>{file.uploadedBy}</td>
                                      <td>{dayjs(file.modifiedAt).format("MM/DD/YYYY h:mm A")}</td>
                                      <td>
                                        {bytes.format(file.docSize, {
                                          unitSeparator: " ",
                                        })}
                                      </td>
                                      {
                                        userInfo?.role !== ROLE.attorney && 
                                        <td>
                                        <div className="d-flex align-items-center icon-document-web-section">
                                          {userInfo?.role === ROLE.client && (
                                            <>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip className="tooltip">
                                                    Download
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="me-4">
                                                  <Download
                                                    onClick={() =>
                                                      handleDownload(
                                                        file.docId,
                                                        file.docName
                                                      )
                                                    }
                                                    data-testid="download"
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                              {!isVideoFile(file.docName) && (
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip className="tooltip">
                                                      Share
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div className="me-4">
                                                    <Share
                                                      onClick={() =>
                                                        handleShare(file.docId)
                                                      }
                                                      data-testid="share"
                                                    />
                                                  </div>
                                                </OverlayTrigger>
                                              )}
                                              {!isVideoFile(file.docName) && (
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip className="tooltip">
                                                      Print
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div className="me-4">
                                                    <Print
                                                      onClick={() =>
                                                        handlePrint(
                                                          file.docId,
                                                          file.docName
                                                        )
                                                      }
                                                      data-testid="print"
                                                    />
                                                  </div>
                                                </OverlayTrigger>
                                              )}
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip className="tooltip">
                                                    Delete
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="me-4">
                                                  <DeleteAction
                                                    onClick={() =>
                                                      handleDelete(
                                                        file.docId,
                                                        file.docName
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                            </>
                                          )}

                                          {userInfo?.role === ROLE.executor && (
                                            <>
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                  <Tooltip className="tooltip">
                                                    Download
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="me-4">
                                                  <Download
                                                    onClick={() =>
                                                      handleDownload(
                                                        file.docId,
                                                        file.docName
                                                      )
                                                    }
                                                    data-testid="download"
                                                  />
                                                </div>
                                              </OverlayTrigger>

                                              {!isVideoFile(file.docName) && (
                                                <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip className="tooltip">
                                                      Print
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div className="me-4">
                                                    <Print
                                                      onClick={() =>
                                                        handlePrint(
                                                          file.docId,
                                                          file.docName
                                                        )
                                                      }
                                                      data-testid="print"
                                                    />
                                                  </div>
                                                </OverlayTrigger>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </td>
                                      }
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
      </Accordion>

      {(isLoading || docDeleteLoading) && <Loader />}

      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(([docId, { progress, docName }]) => (
            <div key={docId} className="progress-bar-document">
              <div className="d-flex flex-column">
                <div>{docName} downloading</div>
                <div className="d-flex align-items-center">
                  <progress value={progress} max={100} />{" "}
                  <div className="ps-2">{progress}%</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <DocumentPreview
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        fileName={fileName}
        selectedDocs={selectedDocs}
        docType={viewDocType}
        docId={docId}
      />

      <ModalPopupType2
        setShowCallback={() => setConfirmModal(false)}
        setYesCallback={handleViewPageNavigation}
        content={`Are you sure want to delete (${docDetails?.docName}) the  file?`}
        showModal={showConfirmModal}
      />

      <ShareDocumentModalPopup
        showModal={showDocModal}
        setShowCallback={() => setShowDocModal(false)}
        docId={docId}
        selectedDocs={selectedDocs}
        setSuccessCallback={() => {
          setShowDocModal(false);
          setSuccessModal(true);
        }}
      />

      <ModalPopup
        show={showSuccessModal}
        setShow={() => setSuccessModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text={sharedDocSuccessText} />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handleNavigation}
        animation={false}
      />
    </>
  );
}
