import React from "react";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import {
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { ReactComponent as KeyboardLeftArrow } from "../../assets/images/keyboard_arrow_left.svg";
export default function EmployeeTitleBar({
  items,
  title,
  hasButton,
  navigateTo,
  buttonText,
  width,
  navigateToPrev,
  hasBackButton,
  hasNewFolderBtn,
  createFolderCallback
}) {
  
  return (
    <div className="d-flex-none d-md-flex d-lg-flex d-xl-flex justify-content-between align-items-center pt-2 pb-1">
      <div>
        {items !== false && <BreadCrumb items={items} />}
        {hasBackButton && (
          <span
            className="keyboard-left-arrow pe-2"
            onClick={navigateToPrev}
            data-testid="back-arrow"
          >
            <KeyboardLeftArrow />
          </span>
        )}
        <span className="page-title">{title}</span>
      </div>
      {hasButton && (
        <Button
          style={{ width: `${width}` }}
          className={`custom-button btn-standard btn-new-firm`}
          type="button"
          onClick={navigateTo}
          data-testid="new-firm-onboard-button"
        >
          {buttonText}
        </Button>
      )}
      
      {
        hasNewFolderBtn && 
        <div className="d-flex justify-content-between flex-column flex-md-row flex-lg-row align-items-center client-title-bar-buttons">
          <div className="pd-mb">
        <OverlayTrigger
            placement="left"
            //trigger="click"
            overlay={
              <Tooltip
                id="new-folder-tooltip"
                className="attorney-new-folder-tooltip"
              >
                Click here to create a new folder
              </Tooltip>
            }
          >
            <Button
            //style={{ width: isMobile ? "12.1875rem" : "8.5rem" }}
            className={`custom-button btn-standard btn-new-firm new-folder mb-1 me-lg-3 me-lg-3 me-md-3`}
            type="button"
            onClick={createFolderCallback}
            data-testid="new-folder-button"
          >
            + <span className="ps-2">Add New Folder</span>
          </Button>
          </OverlayTrigger>
        </div>
      </div>
        
      }
    </div>
  );
}
