import React from "react";
import { Button } from "react-bootstrap";

export default function DeleteFolderContent({setYesCallback, setNoCallback, folderData}) {
  const handleClickYes = () => setYesCallback(false);
  const handleClose = () => setNoCallback(false);
  return (
    <div className="new-folder-delete-msg-container mt-3">
        <div className="text-center delete-title">
            Delete Folder?
        </div>
        <div className="d-flex flex-column justify-content-center new-folder-delete-text mt-3">
            <div className="text-center">This action will delete the folder and all files in this folder and cannot be retrieved</div>
            <div className="text-center mt-2">{`Are you sure you want to delete the folder (${folderData?.rootDocName})?`}</div>
        </div>
      <div className="d-flex justify-content-center mt-4 
      ">
        <Button
          onClick={handleClickYes}
          className="custom-button btn-standard new-folder-submit-btn yes-button me-2"
          data-testid="yes-button"
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          className="custom-button btn-standard btn-nobg no-button"
          data-testid="no-button"
        >
          No
        </Button>
      </div>
    </div>
  );
}
