import React from "react";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import {
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { ReactComponent as KeyboardLeftArrow } from "../../assets/images/keyboard_arrow_left.svg";
import { ChevronDown } from "react-bootstrap-icons";
import {
  // shareBkDetails,
  // receivBkDetails,
  // shareFiles,
  receiveFiles,
  shareReceiveBtn,
} from "../../constants/client/labels";
import { client_personaluse } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

export default function ClientTitleBar({
  items,
  title,
  hasButton,
  navigateTo,
  buttonText,
  width,
  navigateToPrev,
  hasBackButton,
  isMobile,
  createFolderCallback,
  shareReceiveCallback,
  shareBankDetailsCallback,
}) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(client_personaluse);
  };

  const dropdownComp = (
    <>
      <div>
        <span className="pe-1">{shareReceiveBtn} </span>
        <ChevronDown
          size={16}
          className="dropbtn"
          color="#fff"
          data-testid="share-rec-chevronDown-icon"
          //onClick={() => setCollapese(() => !collapse)}
        />
      </div>
    </>
  );
  return (
    <div className="d-flex justify-content-between align-items-center pb-1 pt-2 flex-column flex-md-row flex-lg-row">
      <div>
        {items !== false && <BreadCrumb items={items} />}
        {hasBackButton && (
          <span className="keyboard-left-arrow pe-2" onClick={navigateToPrev}>
            <KeyboardLeftArrow />
          </span>
        )}

        <span className="page-title">{title}</span>
      </div>
      <div className="d-flex justify-content-between flex-column flex-md-row flex-lg-row client-title-bar-buttons">
        <div className="pd-mb">
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip
                id="personal-use-tooltip"
                className="tooltip-personaluse"
              >
                This feature allows you to designate certain folders and files
                as Personal. In the event of your death, these files will be
                permanently deleted and will not be accessible to any party,
                including your executor. However, unless specifically indicated
                otherwise, these files may be accessed by your designated
                professional during your lifetime.
              </Tooltip>
            }
          >
            <Button
              style={{ width: isMobile ? "12.1875rem" : "8.5rem" }}
              className={`custom-button btn-standard btn-new-firm mx-2 mb-1`}
              type="button"
              onClick={handleNavigate}
              data-testid="new-firm-onboard-button"
            >
              Personal Use
            </Button>
          </OverlayTrigger>
        </div>
        <div className="pd-mb">
          <OverlayTrigger
            placement="bottom"
            //trigger="click"
            overlay={
              <Tooltip id="new-folder-tooltip" className="tooltip-new-folder">
                Click here to create a new folder
              </Tooltip>
            }
          >
            <Button
              style={{ width: isMobile ? "12.1875rem" : "8.5rem" }}
              className={`custom-button btn-standard btn-new-firm new-folder mx-2 mb-1 me-lg-3 me-lg-3 me-md-3`}
              type="button"
              onClick={createFolderCallback}
              data-testid="new-folder-button"
            >
              + <span className="ps-2">New Folder</span>
            </Button>
          </OverlayTrigger>
        </div>
        <DropdownButton
          className="custom-dropdown pd-mb"
          id="dropdown-basic-button"
          title={dropdownComp}
          data-testid="dropdown-share-rec-button"
        >
          {/* <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip
                id="tooltip-share-bank-details"
                className="custom-tooltip"
              >
                Enables you to share your bank details to anyone outside or
                within the <b>FamVault</b> platform in the most secure way
              </Tooltip>
            }
          >
            <Dropdown.Item
              className="custom-dropdown-item"
              onClick={() => shareBankDetailsCallback("sharebankdetails")}
              disabled
            >
              {shareBkDetails}
            </Dropdown.Item>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip
                id="tooltip-share-bank-details"
                className="custom-tooltip"
              >
                Enables you to receive your bank details to anyone outside or
                within the <b>FamVault</b> platform in the most secure way
              </Tooltip>
            }
          >
            <Dropdown.Item className="custom-dropdown-item" disabled href="#/action-1">
              {receivBkDetails}
            </Dropdown.Item>
          </OverlayTrigger>

          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => shareReceiveCallback("sharefiles")}
            disabled
          >
            {shareFiles}
          </Dropdown.Item> */}

          <OverlayTrigger
            //key="light"
            placement="left"
            //trigger="click"
            overlay={
              <Tooltip
                id="receive-files-tooltip"
                className="client-dropdown-tooltip"
              >
                Enables users to request other professionals to upload documents
                on their behalf
              </Tooltip>
            }
          >
            <Dropdown.Item
              className="d-flex align-items-center"
              onClick={() => shareReceiveCallback("receivefiles")}
              data-testid="receive-files-item"
            >
              {receiveFiles}
            </Dropdown.Item>
          </OverlayTrigger>
        </DropdownButton>
      </div>
    </div>
  );
}
