import React, { useEffect, useState } from "react";
import {
  Container,
  Accordion,
  Card,
  Form,
  Table,
  Button,
  Alert,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clientTitle, personaluse } from "../../constants/client/labels";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { PlusLg, DashLg } from "react-bootstrap-icons";
import { client_LandingPage, client_personaluse } from "../../constants/routes";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import Loader from "../../components/Loader/Loader";
import { useGetAllDocsQuery } from "../../slices/personalUseApiManagementSlice";
import bytes from "bytes";
import PersonalUseModalPopup from "../../components/modal/PersonalUseModalPopup";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export default function ClientPersonalUse() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState([]);
  const [activeKeySub, setActiveKeySub] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [folderDocTypes, setfolderDocTypes] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [initialSelectedFolders, setInitialSelectedFolders] = useState([]);
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: client_personaluse,
      content: personaluse,
      active: false,
      state: { id: "", activeTab: "" },
    },
  ]);

  const { userInfo } = useSelector((state) => state.auth);
  const { data, error, isLoading, refetch, isFetching } = useGetAllDocsQuery(
    userInfo?.userId,
    {
      skip: userInfo?.userId === undefined,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);
  useEffect(() => {
    if (location?.state) {
      setItems([
        {
          id: "item-1",
          link: client_LandingPage,
          content: clientTitle,
          active: false,
          state: { id: "", activeTab: "" },
        },
        {
          id: "item-2",
          link: client_personaluse,
          content: personaluse,
          active: false,
          state: { id: "", activeTab: "" },
        },
      ]);
    }
  }, [location]);

  useEffect(() => {
    if (data && data?.rootDocumentList && data?.rootDocumentList?.length > 0) {
      setfolderDocTypes(data?.rootDocumentList);
      let selecteddocs = [];
      data?.rootDocumentList.map((docType) => {
        if(docType?.isCustomFolder){
          docType.documentList.map((file) => {
            if (file.isPersonal) {
              selecteddocs.push(file.docId);
            }
            return file.docId;
          });
        }else{
          docType.documentTypeList?.length>0 && docType.documentTypeList.map((doc) => {
            doc.documentList.map((file) => {
              if (file.isPersonal) {
                selecteddocs.push(file.docId);
              }
              return file.docId;
            });
            return doc.rootDocTypeId;
          });
        }
        
        return docType.rootDocId;
      });
      setSelectedFolders(selecteddocs);
      setInitialSelectedFolders(selecteddocs);
    }
  }, [data, location]);
  const handlePrevPage = () => {
    navigate(client_LandingPage);
  };

  const CustomToggleMain = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={decoratedOnClick}
      >
        <span className="pe-3">
          {activeKey.includes(eventKey) ? (
            <DashLg size={18} className="plus-icon" />
          ) : (
            <PlusLg size={18} className="plus-icon" />
          )}
        </span>
        {children}
      </div>
    );
  };

  const CustomToggleSub = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    return <div onClick={decoratedOnClick}>{children}</div>;
  };

  const handleDocument = (e, docIndex, isCustomFolder) => {
    let tempArray;
    console.log("")
    tempArray=[...folderDocTypes].map((doc) => {
      let temp={...doc};
      if(!doc?.isCustomFolder){
        temp.documentTypeList= doc.documentTypeList.map((type) => ({
          ...type,
          documentList: type.documentList.map((docFile) => ({ ...docFile })),
        }))
      }else{
        temp.documentList = doc.documentList.map((docFile) => ({ ...docFile }))
      }
      return temp;
    })
    
    let selectedFoldersCopy = [...selectedFolders];
    if(!isCustomFolder){
      tempArray[docIndex].documentTypeList = tempArray[
        docIndex
      ].documentTypeList.map((docType, docTypeIndex) => {
        docType.documentList = docType.documentList.map((docFile) => {
          docFile.isPersonal = e.target.checked;
          if (e.target.checked) {
            selectedFoldersCopy.push(docFile.docId);
          } else {
            selectedFoldersCopy = selectedFoldersCopy.filter(
              (id) => id !== docFile.docId
            );
          }
          return docFile;
        });
        docType.isAllSelected = e.target.checked;
        return docType;
      });
    }else{
      tempArray[docIndex].documentList = tempArray[
        docIndex
      ].documentList.map((docFile) => {
        docFile.isPersonal = e.target.checked;
          if (e.target.checked) {
            selectedFoldersCopy.push(docFile.docId);
          } else {
            selectedFoldersCopy = selectedFoldersCopy.filter(
              (id) => id !== docFile.docId
            );
          }
        return docFile;
      });
    }
    tempArray[docIndex].isAllSelected = e.target.checked;

     setfolderDocTypes(tempArray);
     setSelectedFolders(selectedFoldersCopy);
  };

  const handleDocumentType = (e, docIndex, docTypeIndex) => {
    
    let tempArray=[...folderDocTypes].map((doc) => {
      let temp={...doc};
      if(!doc?.isCustomFolder){
        temp.documentTypeList= doc.documentTypeList.map((type) => ({
          ...type,
          documentList: type.documentList.map((docFile) => ({ ...docFile })),
        }))
      }else{
        temp.documentList = doc.documentList.map((docFile) => ({ ...docFile }))
      }
      return temp;
    })
    let selectedFoldersCopy = [...selectedFolders];
    tempArray[docIndex].documentTypeList[docTypeIndex].documentList = tempArray[
      docIndex
    ].documentTypeList[docTypeIndex].documentList.map((docFile) => {
      docFile.isPersonal = e.target.checked;
      if (e.target.checked) {
        selectedFoldersCopy.push(docFile.docId);
      } else {
        selectedFoldersCopy = selectedFoldersCopy.filter(
          (id) => id !== docFile.docId
        );
      }
      return docFile;
    });

    tempArray[docIndex].documentTypeList[docTypeIndex].isAllSelected =
      e.target.checked;

    const allDocTypesSelected = tempArray[docIndex].documentTypeList.every(
      (type) => type.isAllSelected
    );
    tempArray[docIndex].isAllSelected = allDocTypesSelected;

    setfolderDocTypes(tempArray);
    setSelectedFolders(selectedFoldersCopy);
  };

  const handleFiles = (e, file, docIndex, fileIndex) => {
    let allFilesSelected;
    let allDocTypesSelected;
    let tempArray=[...folderDocTypes].map((doc) => {
      let temp={...doc};
      if(!doc?.isCustomFolder){
        temp.documentTypeList= doc.documentTypeList.map((type) => ({
          ...type,
          documentList: type.documentList.map((docFile) => ({ ...docFile })),
        }))
      }else{
        temp.documentList = doc.documentList.map((docFile) => ({ ...docFile }))
      }
      return temp;
    })
    let selectedFoldersCopy = [...selectedFolders];

    if(fileIndex !== null){
      tempArray[docIndex].documentTypeList[fileIndex].documentList = tempArray[
        docIndex
      ].documentTypeList[fileIndex].documentList.map((docFile) => {
        if (docFile.docId === file.docId) {
          docFile.isPersonal = e.target.checked;
        }
        return docFile;
      });
    }else{
      tempArray[docIndex].documentList=tempArray[docIndex].documentList.map((docFile) => {
        if (docFile.docId === file.docId) {
          docFile.isPersonal = e.target.checked;
        }
        return docFile;
      })
    }
    
    if (e.target.checked) {
      selectedFoldersCopy.push(file.docId);
    } else {
      selectedFoldersCopy = selectedFoldersCopy.filter(
        (id) => id !== file.docId
      );
    }
    
    if(fileIndex !== null){
      allFilesSelected = tempArray[docIndex].documentTypeList[
        fileIndex
      ].documentList.every((docFile) => docFile.isPersonal);
      tempArray[docIndex].documentTypeList[fileIndex].isAllSelected =
        allFilesSelected;
      allDocTypesSelected = tempArray[docIndex].documentTypeList.every(
        (type) => type.isAllSelected
      );
    }else{
      allFilesSelected = tempArray[docIndex].documentList.every((docFile) => docFile.isPersonal);
      tempArray[docIndex].isAllSelected =
        allFilesSelected;
        allDocTypesSelected=allFilesSelected;  
    }
    
    tempArray[docIndex].isAllSelected = allDocTypesSelected;
    setfolderDocTypes(tempArray);
    setSelectedFolders(selectedFoldersCopy);
  };

  const handleSubmitAccess = () => {
    setShowModal(true);
  };

  const onClickYes = () => {
    setShowModal(false);
    navigate(client_LandingPage);
    toast.success("Details updated successfully");
  };

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <div
        className="superadmin-container view-people-info-container"
        data-testid="client-receive-files-page"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={personaluse}
              hasBackButton={true}
              navigateToPrev={handlePrevPage}
            />
          </div>
        </Container>
      </div>
      <div
        className="firm-page-outer-container"
        data-testid="attorney-view-successor-page"
      >
        <Container fluid className="px-lg-4">
          <div className="firm-page-container attorney-view-page employee-form-container px-lg-4 px-3 ">
            <div className="personal-use-doc-list px-2 px-lg-4 px-md-4 px-xl-1 pb-2 pt-3">
              <div className="receive-file-note">
                <span className="note-label">Note: </span>Safeguard your privacy
                with our unique platform feature. Once marked as private,
                selected documents will be automatically erased upon your
                passing, ensuring they remain confidential and cannot be
                retrieved.
              </div>
              <div className="mt-4">
                <Accordion
                  className="mb-1"
                  activeKey={activeKey}
                  alwaysOpen
                  onSelect={(selectedIndex) => setActiveKey(selectedIndex)}
                >
                  {folderDocTypes &&
                    folderDocTypes.length > 0 &&
                    folderDocTypes.map((folder, index) => {

                      return (
                        <Card
                          className="custom-card mb-2"
                          key={folder.rootDocId}
                        >
                          <Card.Header className="custom-card-header d-flex">
                            <Form.Check
                              inline
                              name="group1"
                              type="checkbox"
                              id={`folder-checkbox-${index}`}
                              checked={folder.isAllSelected}
                              data-testid={`folder-checkbox-${index}`}
                              onChange={(e) => handleDocument(e, index, folder?.isCustomFolder?true:false)}
                            />
                            <CustomToggleMain eventKey={folder.rootDocId}>
                              <span data-testid={`folder-name-${index}`}>
                                {(window.innerWidth < 576 &&
                                  folder.rootDocName.length < 25) ||
                                window.innerWidth > 576
                                  ? folder.rootDocName
                                  : folder.rootDocName.slice(0, 22) + "..."}
                              </span>
                            </CustomToggleMain>
                          </Card.Header>
                          <Accordion.Collapse eventKey={folder.rootDocId}>
                            <Card.Body className="card-body-main">
                              <Accordion
                                activeKey={activeKeySub}
                                alwaysOpen
                                onSelect={(selectedIndex) =>
                                  setActiveKeySub(selectedIndex)
                                }
                              >
                                {
                                  folder?.isCustomFolder ?
                                  <div className="doctype-table-container">
                                    <Table hover className="table">
                                      <thead>
                                        <tr>
                                          <th className="col-1"></th>
                                          <th className="col-4">
                                            File Name
                                          </th>
                                          <th className="col-2">
                                            Uploaded By
                                          </th>
                                          <th className="col-3">
                                            Last Modified
                                          </th>
                                          <th className="col-2">
                                            File Size
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {folder.documentList &&
                                        folder.documentList
                                          .length > 0 ? (
                                            folder.documentList.map(
                                            (file, fileIndex) => (
                                              <tr key={file.docId}>
                                                <td className="col-1"></td>
                                                <td className="col-4">
                                                  <Form.Check
                                                    inline
                                                    style={{
                                                      marginLeft:
                                                        "-25px",
                                                    }}
                                                    name="group1"
                                                    type="checkbox"
                                                    id={`inline-radio-1`}
                                                    value={
                                                      file.docId
                                                    }
                                                    checked={
                                                      file.isPersonal
                                                    }
                                                    data-testid={`sub-folder-${index}${fileIndex}`}
                                                    onChange={(e) =>
                                                      handleFiles(
                                                        e,
                                                        file,
                                                        index,
                                                        null
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    data-testid="file-name"
                                                    className="ps-2"
                                                  >
                                                    {file.docName}
                                                  </span>
                                                </td>
                                                <td className="col-3">
                                                  {file.uploadedBy}
                                                </td>
                                                <td className="col-3">
                                                  {dayjs(file.modifiedAt).format("MM/DD/YYYY h:mm A")}
                                                </td>
                                                <td className="col-2">
                                                  {bytes.format(
                                                    file.docSize,
                                                    {
                                                      unitSeparator:
                                                        " ",
                                                    }
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="4"
                                              className="text-center"
                                            >
                                              No files available
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                  :
                                  <>
                                <div className="doctype-table-container">
                                  <Table hover>
                                    <thead>
                                      <tr>
                                        <th scope="col" className="col-2">
                                          Document Type
                                        </th>
                                      </tr>
                                    </thead>
                                  </Table>
                                </div>
                                {folder?.documentTypeList &&
                                  folder?.documentTypeList?.length > 0 &&
                                  folder?.documentTypeList?.map(
                                    (category, doci) => {
                                      return (
                                        <Card
                                          className="custom-card-sub"
                                          key={category.rootDocTypeId}
                                        >
                                          <Card.Header
                                            className="custom-card-header-subfolder d-flex ps-5"
                                            style={{
                                              backgroundColor:
                                                doci % 2 === 0
                                                  ? "white"
                                                  : "rgb(250, 250, 250)",
                                            }}
                                          >
                                            <Form.Check
                                              inline
                                              name="group1"
                                              type="checkbox"
                                              id={`folder-checkbox-${doci}`}
                                              checked={category.isAllSelected}
                                              data-testid={`folder-checkbox-${doci}`}
                                              onChange={(e) =>
                                                handleDocumentType(
                                                  e,
                                                  index,
                                                  doci
                                                )
                                              }
                                            />
                                            <CustomToggleSub
                                              eventKey={category.rootDocTypeId}
                                            >
                                              <div>
                                                <span className="pe-4">
                                                  {activeKeySub.includes(
                                                    category.rootDocTypeId
                                                  ) ? (
                                                    <DashLg
                                                      size={18}
                                                      className="plus-icon"
                                                    />
                                                  ) : (
                                                    <PlusLg
                                                      size={18}
                                                      className="plus-icon"
                                                    />
                                                  )}
                                                </span>
                                                <span
                                                  data-testid={`folder-name-${doci}`}
                                                >
                                                  {(window.innerWidth < 576 &&
                                                    category.rootDocTypeName
                                                      .length < 25) ||
                                                  window.innerWidth > 576
                                                    ? category.rootDocTypeName
                                                    : category.rootDocTypeName.slice(
                                                        0,
                                                        22
                                                      ) + "..."}
                                                </span>
                                              </div>
                                            </CustomToggleSub>
                                          </Card.Header>
                                          <Accordion.Collapse
                                            eventKey={category.rootDocTypeId}
                                          >
                                            <Card.Body className="card-body-sub">
                                              <div className="doctype-table-container">
                                                <Table hover className="table">
                                                  <thead>
                                                    <tr>
                                                      <th className="col-1"></th>
                                                      <th className="col-4">
                                                        File Name
                                                      </th>
                                                      <th className="col-2">
                                                        Uploaded By
                                                      </th>
                                                      <th className="col-3">
                                                        Last Modified
                                                      </th>
                                                      <th className="col-2">
                                                        File Size
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {category.documentList &&
                                                    category.documentList
                                                      .length > 0 ? (
                                                      category.documentList.map(
                                                        (file, fileIndex) => (
                                                          <tr key={file.docId}>
                                                            <td className="col-1"></td>
                                                            <td className="col-4">
                                                              <Form.Check
                                                                inline
                                                                style={{
                                                                  marginLeft:
                                                                    "-25px",
                                                                }}
                                                                name="group1"
                                                                type="checkbox"
                                                                id={`inline-radio-1`}
                                                                value={
                                                                  file.docId
                                                                }
                                                                checked={
                                                                  file.isPersonal
                                                                }
                                                                data-testid={`sub-folder-${index}${fileIndex}`}
                                                                onChange={(e) =>
                                                                  handleFiles(
                                                                    e,
                                                                    file,
                                                                    index,
                                                                    doci                                                                  )
                                                                }
                                                              />
                                                              <span
                                                                data-testid="file-name"
                                                                className="ps-2"
                                                              >
                                                                {file.docName}
                                                              </span>
                                                            </td>
                                                            <td className="col-3">
                                                              {file.uploadedBy}
                                                            </td>
                                                            <td className="col-3">
                                                              {dayjs(file.modifiedAt).format("MM/DD/YYYY h:mm A")}
                                                            </td>
                                                            <td className="col-2">
                                                              {bytes.format(
                                                                file.docSize,
                                                                {
                                                                  unitSeparator:
                                                                    " ",
                                                                }
                                                              )}
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    ) : (
                                                      <tr>
                                                        <td
                                                          colSpan="4"
                                                          className="text-center"
                                                        >
                                                          No files available
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            </Card.Body>
                                          </Accordion.Collapse>
                                        </Card>
                                      );
                                    }
                                  )}
                                  </>
                                }
                              </Accordion>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                </Accordion>
              </div>
              {folderDocTypes && folderDocTypes.length > 0 && (
                <div className="d-flex justify-content-end mt-3 mb-4">
                  <Button
                    style={{ width: "8.5rem" }}
                    className={`custom-button btn-standard ${
                      JSON.stringify(selectedFolders) ===
                        JSON.stringify(initialSelectedFolders) && "btn-disable"
                    }`}
                    type="button"
                    onClick={handleSubmitAccess}
                    data-testid="personal-use-update-button"
                  >
                    Update
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
      <PersonalUseModalPopup
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        setSuccessCallback={onClickYes}
        selectedFolders={selectedFolders}
      />
      {(isLoading || isFetching) && <Loader data-testid="loader" />}
    </>
  );
}
