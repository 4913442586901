export const clientTitle = "Command Center";
export const shareBkDetails = "Share Bank Details";
export const receivBkDetails = "Receive Bank Details";
export const shareFiles = "Share Files";
export const receiveFiles = "Receive Files";
export const shareReceiveBtn = "Share & Receive";
export const shareBankDetails = "Share Bank Details";
export const clientModalMessage =
  "Please verify the person’s details before proceeding further to ensure complete security";

export const personaluse =  "Personal Use";
export const dashboard = "Dashboard";
export const changeAttorneyLabels = {
  currentProfessional: "Current Professional",
  mobileNo: "Mobile phone",
  emailId: "Email",
  newAttorneyDetails: "New Professional Details",
  attorneyName: "Professional Name",
};
export const receivefilesNote = "A key feature of our platform allows members to grant temporary access to trusted professionals, like insurance agents, enabling them to upload relevant documents directly to your secure folder. This eliminates the hassle of collecting documents from multiple sources and adding them individually, ensuring all your important information is up-to-date and easily accessible when needed";
export const optionalExeValidationMessage = "Please note that the information required will be used to validate the identity of your Executor. Without complete information, it may be difficult to validate the identity of your Executor and delay their access to your information after you pass. You are able to update Executor information at any time."
export const optionalWitValidationMessage = "Please note securing the contact information for the witnesses of your will may be crucial for your Executor at the time of your passing. Please make an effort to secure that information and add it to your Command Center as soon as you can.";

export const viewpageLabel = {
  name:"Name",
  dob:"DOB",
  email:"Email",
  mobilePhone:"Mobile Phone",  
  driversLicPassport: "Driver’s License / Passport",
  address:"Address",
  zip:"ZIP",
  city:"City",
  state:"State",
  country:"Country",
  executorfieldsError:"Executors Email, Mobile Phone or Driver’s License / Passport must not be the same"
}
export const editEmailMessage = "After changing your email address, you will be signed out of your account for security reasons. Please log in again using your new email address. ";
