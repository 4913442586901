import React, { useState } from "react";
import {
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ROLE } from "../../constants/role";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Loader/Loader";
import { ReactComponent as Download } from "../../assets/images/download-icon.svg";
import { ReactComponent as DeleteAction } from "../../assets/images/DeleteAction.svg";
import { signOutApiCall } from "../../slices/authSlice";
import { useDeleteAdditionalProfDocumentMutation } from "../../slices/additionalProfApiManagementSlice";
import ModalPopupType2 from "../modal/ModalPopupType2";
export default function AdditionalProfNewfolderDocumentDetails({
  documentGroup,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [progressMap, setProgressMap] = useState({});
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [docDetails, setDocDetails] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const [deleteDocument, { isLoading: docDeleteLoading }] =
    useDeleteAdditionalProfDocumentMutation();
  const handleDownload = async (docId, docName) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/professional/${docId}?identifier=${location?.state?.doc?.identifier}`;
    const token = userInfo?.accessToken;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;

          if (totalLength !== null) {
            setLoading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            // Update progress for this specific file
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docId]: { progress, docName },
            }));
          }
        },
      });

      if (response.status === 200) {
        setLoading(false);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${docName}`;
        a.setAttribute("id", "download-doc");
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        // Handle downloaded file data
        toast.success(`${docName} has been downloaded successfully!`);
        setTimeout(() => {
          setProgressMap((prevProgressMap) => {
            const updatedProgressMap = { ...prevProgressMap };
            delete updatedProgressMap[docId];
            return updatedProgressMap;
          });
        }, 2000);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signOutApiCall());
        toast.error(`Signed out successfully due to token expiration!`);
      }
      setLoading(false);
      console.error("Error downloading file:", error);
      toast.error(`${docName} downloading failed. Try again!`);
      setProgressMap((prevProgressMap) => {
        const updatedProgressMap = { ...prevProgressMap };
        delete updatedProgressMap[docId];
        return updatedProgressMap;
      });
    }
  };
  const handleDelete = (docId, docName) => {
    setConfirmModal(true);
    setDocDetails({ docId, docName });
  };
  function isVideoFile(filename) {
    // Define a regular expression pattern to match video file extensions
    const videoFileExtensions =
      /\.(mp4|m4v|mkv|mov|avi|wmv|flv|webm|mpeg|mpg|3gp|3g2)$/i;

    // Test the filename against the pattern
    return videoFileExtensions.test(filename);
  }
  const handleViewPageNavigation = async () => {
    const { docId } = docDetails;
    try {
      const res = await deleteDocument({
        docId,
        identifierId: location?.state?.doc?.identifier,
      }).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
        setConfirmModal(false);
      } else {
        setConfirmModal(false);
        toast.error(res?.message);
      }
    } catch (err) {
      setConfirmModal(false);
      console.error("Error deleting document:", err);
      toast.error(err?.error || err?.data?.error);
    }
  };

  return (
    <>
      <div className="new-folder-document-list pt-3">
        <Card
          key={documentGroup[0].rootDocTypeId}
          className="custom-card mb-1 custom-card-shadow"
        >
        <Card.Body className="custom-card-body">
            <div className="doc-section">
              <div className="doc-inner-section multiple d-flex justify-content-between d-block d-md-none ">
                {documentGroup.map((file, index) => (
                  <div key={index} className="position-relative">
                    <div>
                      <div>File Name</div>
                      <div
                        className="file-name-others"
                      >
                        {file.docName}
                      </div>
                    </div>
                    {
                      <div className="mt-2">
                        <div className="delete-doc-others d-flex small">
                          {
                            !isVideoFile(file.docName) && (
                              <div className="me-3">
                                <Download
                                  onClick={() =>
                                    handleDownload(file.docId, file.docName)
                                  }
                                  data-testid="download"
                                />
                              </div>
                            )
                          }
                          <div>
                            <DeleteAction
                              onClick={() =>
                                handleDelete(file.docId, file.docName)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    }
                    {index === documentGroup.length - 1 ? null : <hr />}
                  </div>
                ))}
              </div>
              <div className="d-none d-md-block">
                {/* <Table className="table table-responsive table-custom-document"> */}
                <table className="table-responsive w-100">
                  <thead className="table-custom-document">
                    <tr>
                      <td style={{ width: "30%" }}>File Name</td>
                      <td style={{ width: "20%" }}></td>
                      <td style={{ width: "20%" }}></td>
                      <td style={{ width: "15%" }}></td>
                      {userInfo?.role !== ROLE.attorney && (
                        <td style={{ width: "15%" }}></td>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {documentGroup.map((file, index) => (
                      <tr key={index}>
                        <td>
                          <div className="file-name-others">
                            <div>{file.docName}</div>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <div className="d-flex align-items-center icon-document-web-section">
                            {
                              <>
                              {
                                !isVideoFile(file.docName) && (
                                  <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip className="tooltip">
                                      Download
                                    </Tooltip>
                                  }
                                >
                                  <div className="me-4">
                                    <Download
                                      onClick={() =>
                                        handleDownload(file.docId, file.docName)
                                      }
                                      data-testid="download"
                                    />
                                  </div>
                                </OverlayTrigger>
                                )
                              }
                              <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip className="tooltip">
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <div className="me-4">
                                    <DeleteAction
                                      onClick={() =>
                                        handleDelete(file.docId, file.docName)
                                      }
                                    />
                                  </div>
                                </OverlayTrigger>
                              </>
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* </Table> */}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {(isLoading || docDeleteLoading) && <Loader />}
      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(([docId, { progress, docName }]) => (
            <div key={docId} className="progress-bar-document">
              <div className="d-flex flex-column">
                <div>{docName} downloading</div>
                <div className="d-flex align-items-center">
                  <progress value={progress} max={100} />{" "}
                  <div className="ps-2">{progress}%</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <ModalPopupType2
        setShowCallback={() => setConfirmModal(false)}
        setYesCallback={handleViewPageNavigation}
        content={`Are you sure want to delete (${docDetails?.docName}) the  file?`}
        showModal={showConfirmModal}
      />
    </>
  );
}
