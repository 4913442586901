import React from "react";
import { Modal } from "react-bootstrap";

export default function OtpShareLinkPopup({
  showModal,
  setShowCallback,
  retryDownload
}) {

  const handleClose = () => {
    setShowCallback(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="personal-use-modal"
        data-testid="personal-use-modal-container"
      >
        <Modal.Header
          className="share-doc-modal-header receive-file-header"
          closeButton
        >
        </Modal.Header>
        <Modal.Body className="">
        <div className="d-flex justify-content-center">
            <div className="text-align-center mx-3 mb-4 mt-2">
            <b>
            If your download hasn't started automatically, please {" "}
            <button onClick={retryDownload} style={{ textDecoration: 'underline', color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>           
              <b>click here</b>
            </button>{" "}
            .If you received a new OTP on your mobile, please re-enter it to continue.
            </b>           
            </div>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
