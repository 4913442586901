import { folderApiSlice } from "./folderApiSlice";
import { documentApiManagement } from "./documentApiManagement";
export const folderApiManagement = folderApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createFolder: builder.mutation({
            query: (data) => ({
              url: `/${data.clientId}/create?userId=${data.userId}&colorId=${data.color}&name=${data.name}`,
              method: "POST",
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(
                  documentApiManagement.util.invalidateTags(["getfoldersByid"])
                );
              }
        }),
        updateFolder: builder.mutation({
            query: (data) => ({
                url: `/${data.clientId}/update?entityId=${data.entityId}&colorId=${data.color}&name=${data.name}`,
                method: "PUT",
                body:data
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                await queryFulfilled;
                dispatch(
                  documentApiManagement.util.invalidateTags(["getfoldersByid"])
                );
              }
        }),
        deleteFolder: builder.mutation({
            query: (data) => ({
                url: `/${data.clientId}/delete?entityId=${data.entityId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["getfoldersByid"],
        })   
    }),
  });

  export const {
    useCreateFolderMutation,
    useUpdateFolderMutation,
    useDeleteFolderMutation
  } = folderApiManagement;
